import React, { useContext, useMemo } from "react";
import constants from "../../constants";
import MonnifyIcons from "../../icon";
import {
  BANK_ICON,
  CARD_ICON,
  NQR_ICON,
  PHONE_ICON,
  TRANSFER_ICON,
  USSD_ICON,
} from "../../icon/icon";
import PayWithBank from "../../page/bank";
import PayWithNQR from "../../page/nqr";
import PayWithCard from "../../page/pay-with-card";
import PayWithPhone from "../../page/pay-with-phone";
import BankTranfer from "../../page/transfer";
import PayWithUSSD from "../../page/ussd";
import StoreContext from "../../services/store/store-context";
import Header from "../header";
import { STAGES as APP_STAGE } from "../../App";
import Success from "../../page/transaction-status/Success";
import Failed from "../../page/transaction-status/Failed";
import Rejected from "../../page/transaction-status/Rejected";
import PayWithCardTest from "../../page/pay-with-card-test";

const MobileMenuSelect = ({ paymentMethod, onPaymentMethodChange }) => {
  const context = useContext(StoreContext);

  const showPaymentMethod = () => {
    switch (paymentMethod) {
      case constants.PAY_WITH_CARD:
        return (
          <>
            {context.isTestMode ? (
              <PayWithCardTest className="no-horizontal-padding" />
            ) : (
              <PayWithCard className="no-horizontal-padding" />
            )}
          </>
        );
      case constants.PAY_WITH_ACCOUNT_TRANSFER:
        return <BankTranfer className="no-horizontal-padding" />;
      case constants.PAY_WITH_USSD:
        return <PayWithUSSD className="no-horizontal-padding" />;
      case constants.NQR:
        return <PayWithNQR className="no-horizontal-padding" />;
      case constants.PAY_WITH_PHONE_NO:
        return <PayWithPhone className="no-horizontal-padding" />;
      case constants.PAY_WITH_BANK:
        return <PayWithBank className="no-horizontal-padding" />;
      default:
        return null;
    }
  };
  const getCardType = () => {
    switch (paymentMethod) {
      case constants.PAY_WITH_CARD:
        return CARD_ICON;
      case constants.PAY_WITH_ACCOUNT_TRANSFER:
        return TRANSFER_ICON;
      case constants.PAY_WITH_USSD:
        return USSD_ICON;
      case constants.NQR:
        return NQR_ICON;
      case constants.PAY_WITH_PHONE_NO:
        return PHONE_ICON;
      case constants.PAY_WITH_BANK:
        return BANK_ICON;
      default:
        return CARD_ICON;
    }
  };

  const enabledPaymentMethods = useMemo(
    () => context.getEnabledPaymentMethods(),
    [context?.paymentInfo?.paymentData]
  );

  const showPaymentStage = (context) => {
    switch (context.stage) {
      case APP_STAGE.TRANSACTION_PROCESSING:
        return showContent();
      case APP_STAGE.TRANSACTION_SUCCESSFUL:
        return <Success />;
      case APP_STAGE.TRANSACTION_REJECTED:
        return <Rejected />;
      case APP_STAGE.TRANSACTION_FAILED:
        return <Failed />;
      default:
        return showContent();
    }
  };
  const showContent = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12 no-horizontal-padding">
            <ul className="mobile-menu">
              <li className="active mb-0">
                <div className="d-flex">
                  <span>
                    <MonnifyIcons type={getCardType()} />

                    <span>{paymentMethod}</span>
                  </span>
                  {enabledPaymentMethods.length > 1 && (
                    <span
                      onClick={() => onPaymentMethodChange()}
                      className="icon-forward text-primary f-w-500 "
                    >
                      SWITCH
                    </span>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 no-horizontal-padding">
            <div className="bg-white b-bx-16 border-top-rounded">
              <div className="mobile-wrapper-content px-2">
                {showPaymentMethod()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="py-2"></div>
      <div className="px-2">
        <Header />
      </div>
      {showPaymentStage(context)}
    </>
  );
};

export default MobileMenuSelect;
