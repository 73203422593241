import React, { useContext, useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import SelectInput from "../../component/select";
import StoreContext from "../../services/store/store-context";
import { RESPONSE_CODE } from "../../services/transaction-status.constant";
import TransactionService from "../../services/transaction.service";
import { STAGES as APP_STAGE } from "../../App";
import Loader from "../../component/loader";
import Fade from "react-reveal/Fade";
import MonnifyIcons from "../../icon";
import { SPINNER_WHITE } from "../../icon/icon";
import constants from "../../constants";
import { PAY_WITH_OTHER_METHODS } from "../../services/constants";

const SelectBank = ({ onBankSelected }) => {
  const context = useContext(StoreContext);
  const [banks, setBanks] = useState([]);
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const initializeTransaction = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await TransactionService.initializeBankTransfer(
          {
            collectionChannel:
              context?.paymentInfo?.configData?.collectionChannel,
            apiKey: context?.paymentInfo?.paymentData?.apiKey,
            transactionReference: params.id,
          },
          context?.paymentInfo?.configData?.apiUrl
        );
        let responseData = (response && response.data) || {};

        if (
          !responseData ||
          !responseData.requestSuccessful ||
          !responseData.responseBody
        ) {
          // display error message
          reject(responseData);
          return;
        }
        let responseBody = responseData.responseBody;

        context.updatePaymentData({
          paymentData: {
            ...context?.paymentInfo?.paymentData,
            amount: responseBody.authorizedAmount,
            totalAmountPayable: responseBody.authorizedAmount,
            paymentCode: responseBody.paymentCode,
            providerReference: responseBody.providerReference,
            ussdCode: responseBody.ussdCode,
          },
        });

        resolve(null);
      } catch (error) {
        let errorData = (error && error.response && error.response.data) || {};
        reject(errorData);
      }
    });
  }, [
    context?.paymentInfo?.configData?.collectionChannel,
    context?.paymentInfo?.paymentData?.apiKey,
    context?.paymentInfo?.configData?.apiUrl,
    params.id,
  ]);

  const handleErrorLoadingAccount = useCallback((data) => {
    setLoading(false);
    const error = `Unable to use this payment option at the moment. Please try again shortly. If the issue persists, contact support.`;
    const responseCode = error.responseCode;
    console.log("error is : ", error);

    if (responseCode === RESPONSE_CODE.TRANSACTION_COMPLETED) {
      context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
      return;
    }
    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      error,
      "Transaction Failed",
      [
        {
          text: "Try again with USSD",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_USSD
            );
          },
        },
      ]
    );
  }, []);
  const getBanks = useCallback(async () => {
    try {
      setLoading(true);
      // await initializeTransaction()
      const response = await TransactionService.getBanks(
        context?.paymentInfo?.configData?.apiUrl
      );

      const responseData = response.data || {};
      const data = responseData.responseBody || [];
      setLoading(false);
      setBanks(data);
    } catch (error) {
      setLoading(false);
      const errorData = (error && error.response && error.response.data) || {};
      // do something with the error
      handleErrorLoadingAccount(errorData);
    }
  }, [
    context?.paymentInfo?.configData?.apiUrl,
    handleErrorLoadingAccount,
    initializeTransaction,
  ]);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  const onUSSDSelect = async (code) => {
    try {
      const bank = banks.find((item) => item.baseUssdCode === code);
      const bankUssdCode = code.replace("*", "").replace("#", "");
      context.updatePaymentData({
        paymentData: {
          ...context?.paymentInfo?.paymentData,
          bankUssdCode,
          bank,
        },
      });
      onBankSelected();
    } catch (error) {
      setLoading(false);
      const errorData = (error && error.response && error.response.data) || {};
      handleErrorLoadingAccount();
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Fade bottom>
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-center sub-title text-black">
                Select your bank to begin payment
              </h3>
            </div>
          </div>
          <div className="py-2"></div>
          <div className="row">
            <div className="col-md-12 no-horizontal-padding">
              <SelectInput
                placeholder={"Choose bank"}
                onSelect={(e) => onUSSDSelect(e.target.value)}
                options={banks.map((item) => ({
                  label: item.name,
                  value: item.baseUssdCode,
                }))}
              />
            </div>
          </div>
        </Fade>
      )}
    </>
  );
};

export const LoadingBanner = () => {
  return (
    <Fade top>
      <div className="custom-bg-light py-0 px-2">
        <span className="text-white">Loading...</span>
        <span>
          <MonnifyIcons type={SPINNER_WHITE} />
        </span>
      </div>
    </Fade>
  );
};

export default SelectBank;
