import React, { useContext, useState, useCallback, useEffect } from "react";
import SelectInput from "../../component/select";
import StoreContext from "../../services/store/store-context";
import { STAGES as APP_STAGE } from "../../App";
import TransactionService from "../../services/transaction.service";
import { RESPONSE_CODE } from "../../services/transaction-status.constant";
import Loader from "../../component/loader";
import constants from "../../constants";
import { Fade } from "react-reveal";
import { PAY_WITH_OTHER_METHODS } from "../../services/constants";

const SelectBank = ({ onBankSelected }) => {
  const context = useContext(StoreContext);
  const [banks, setBanks] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleErrorLoadingAccount = useCallback((data) => {
    setLoading(false);
    const error = `Unable to use this payment option at the moment. Please try again shortly. If the issue persists, contact support.`;
    const responseCode = error.responseCode;

    if (responseCode === RESPONSE_CODE.TRANSACTION_COMPLETED) {
      context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
      return;
    }
    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      error,
      "Temporary Error",
      [
        {
          text: "Try again with USSD",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            console.log("called");
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_BANK
            );
          },
        },
      ]
    );
  }, []);
  const getBanks = useCallback(async () => {
    try {
      setLoading(true);
      // await initializeTransaction()
      const response = await TransactionService.getPayWithBankSupportedBanks(
        context?.paymentInfo?.configData?.apiUrl
      );

      const responseData = response.data || {};
      const data = responseData.responseBody || [];
      setLoading(false);

      setBanks(data);
    } catch (error) {
      setLoading(false);
      const errorData = (error && error.response && error.response.data) || {};
      // do something with the error
      handleErrorLoadingAccount(errorData);
    }
  }, [context?.paymentInfo?.configData?.apiUrl, handleErrorLoadingAccount]);

  useEffect(() => {
    getBanks();
  }, [getBanks]);
  const onSelect = (code) => {
    const bank = banks.find((item) => item.code === code);

    const bankUssdCode = code.replace("*", "").replace("#", "");
    context.updatePaymentData({
      paymentData: {
        ...context?.paymentInfo?.paymentData,
        bankUssdCode: code,
        bank,
      },
    });
    onBankSelected(bankUssdCode);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Fade bottom>
          <div className="row">
            <div className="col-md-12 no-horizontal-padding">
              <h3 className="text-center sub-title text-black">
                Select your bank to begin payment
              </h3>
            </div>
          </div>
          <div className="py-2"></div>
          <div className="row">
            <div className="col-md-12 no-horizontal-padding">
              <SelectInput
                placeholder={"Choose One"}
                onSelect={(e) => onSelect(e.target.value)}
                options={banks.map((item) => ({
                  label: item.name,
                  value: item.code,
                }))}
              />
            </div>
          </div>
        </Fade>
      )}
    </>
  );
};

export default SelectBank;
