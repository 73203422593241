import { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import StoreContext from "../services/store/store-context";
import { useContext } from "react";

const useCountDown = () => {
  const context = useContext(StoreContext);

  const [mins, setMins] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [totalSeconds, setTotalSeconds] = useState(0);

  const countDownTimer = useRef(0);

  const intervalRef = useRef();

  const countDown = useCallback(() => {
    if (intervalRef.current) return; // Avoid creating multiple intervals

    intervalRef.current = setInterval(() => {
      setTotalSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, [setMins, setSeconds, setTotalSeconds]);

  useEffect(() => {
    const mins = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    setMins(mins);
    setSeconds(secs);
  }, [totalSeconds]);

  useEffect(() => {
    const validity =
      context?.paymentInfo?.paymentData?.bankTransferData?.validity;
    setTotalSeconds(validity);
  }, [context?.paymentInfo?.paymentData?.bankTransferData?.validity]);

  useEffect(() => {
    countDown();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [countDown]);

  useEffect(() => {
    context.updatePaymentData({
      paymentData: {
        ...context?.paymentInfo?.paymentData,
        bankTransferData: {
          ...context?.paymentInfo?.paymentData?.bankTransferData,
          validityBalance:
            context?.paymentInfo?.paymentData?.bankTransferData
              ?.validityBalance + 1,
        },
      },
    });
  }, [mins, seconds]);

  return [mins, seconds];
};

export default useCountDown;
