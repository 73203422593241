import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import "./index.scss";
import MonnifyIcons from "../../icon";
import { ERROR_INFO, SPINNER_WHITE } from "../../icon/icon";
import { Button } from "../../styled-components/Input";
import { useParams } from "react-router-dom";
import Select from "../../component/select";
import StoreContext from "../../services/store/store-context";
import TransactionService from "../../services/transaction.service";
import SockJsClient from "react-stomp";
import Loader from "../../component/loader";
import { STAGES as APP_STAGE } from "../../App";
import {
  isTransactionPending,
  isTransactionRejected,
  isTransactionSuccessful,
  isPaymentSessionPending,
  RESPONSE_CODE,
  TRANSACTION_STATUS,
  isTransactionExpired,
} from "../../services/transaction-status.constant";
import { ERROR_BANNER, PAY_WITH_OTHER_METHODS } from "../../services/constants";
import Fade from "react-reveal/Fade";
import ClipBoard from "../../component/ClipBoard";
import ProgressBar2 from "./ProgressBar2";
import PaymentProgress from "./PaymentProgress";
import classNames from "classnames";
import constants from "../../constants";

const BankTranfer = (props) => {
  const context = useContext(StoreContext);
  const params = useParams();
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bank, setBank] = useState("");
  const [validatyTime, setValidityTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [socketTopics, setSocketTopics] = useState("");
  const [socketUrl, setSocketUrl] = useState("");
  const [listenForPayment, setListenForPayment] = useState(false);
  let socketClientRef = useRef(null);
  const [messageReceived, setMessageReceived] = useState(null);
  const [isConnected, setIsWebsocketConnected] = useState(null);
  const [paymentSessionStatus, setPaymentSessionStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [isAccountDetailsDisplayed, setIsAccountDetailsDisplayed] =
    useState(false);
  const [showError, setShowError] = useState(false);
  const [banks, setBanks] = useState([]);
  const app_environment = context.getEnvironment();
  const paymentStatusIntervalIdRef = useRef();

  const PAYMENT_STATUS_CONFIG = useMemo(
    () => ({
      poll: 10000,
      enableCheckPaymentButton: 180000,
      clearTimeOutForButton: 2000,
    }),
    []
  );

  const RECONNECT_INTERVAL = 10000;

  const getBanks = useCallback(async () => {
    try {
      const response = await TransactionService.getBanks(
        context?.paymentInfo?.configData?.apiUrl
      );
      const responseData = response.data || {};
      const data = responseData.responseBody || [];

      setBanks(data);
    } catch (error) {
      // do something with the error
    }
  }, [context?.paymentInfo?.configData?.apiUrl]);

  const handleErrorLoadingAccount = (data) => {
    setLoadingFailed(true);

    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      "Unable to generate an account number for this payment option at the moment. Please try again shortly. If the issue persists, contact support.",
      "Temporary Error",
      [
        {
          text: "Try again",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_ACCOUNT_TRANSFER
            );
          },
        },
      ]
    );
  };

  const listenForPaymentOnSocket = useCallback(() => {
    let socketTopics = [];
    let url = `${context?.paymentInfo?.configData?.webSocketUrl}`;
    let topic = `/transaction/${params.id}`;
    socketTopics.push(topic);

    setSocketTopics(socketTopics);
    setSocketUrl(url);
    setListenForPayment(true);
  }, [
    context?.paymentInfo?.configData?.webSocketUrl,
    context.transactionReference,
  ]);

  const initializeTransaction = useCallback(async () => {
    try {
      if (!context || !context.paymentInfo) return;
      setLoading(true);
      const response = await TransactionService.initializeBankTransfer(
        {
          collectionChannel:
            context?.paymentInfo?.configData?.collectionChannel,
          apiKey: context?.paymentInfo?.paymentData?.apiKey,
          transactionReference: params.id,
        },
        context?.paymentInfo?.configData?.apiUrl
      );
      let responseData = (response && response.data) || {};
      setLoading(false);
      if (
        !responseData ||
        !responseData.requestSuccessful ||
        !responseData.responseBody
      ) {
        // display error message
        handleErrorLoadingAccount(responseData);
        return;
      }
      let responseBody = responseData.responseBody;

      setLoadingFailed(false);

      setAccountNumber(responseBody.accountNumber);
      setAccountName(responseBody.accountName);
      setBank(responseBody.bankName);
      setIsAccountDetailsDisplayed(true);
      const validity = responseBody.accountDurationSeconds;
      setValidityTime(validity);

      context.updatePaymentData({
        paymentData: {
          ...context?.paymentInfo?.paymentData,
          amount: responseBody.amount,
          totalPayable: responseBody.totalPayable,
          bankTransferData: {
            accountNumber: responseBody.accountNumber,
            accountName: responseBody.accountName,
            bankName: responseBody.bankName,
            expiresOn: responseBody.expiresOn,
            validity,
            validityBalance: 0,
          },
        },
      });

      listenForPaymentOnSocket();
    } catch (error) {
      setLoading(false);
      let errorData = (error && error.response && error.response.data) || {};
      let responseCode = errorData.responseCode;

      if (responseCode === RESPONSE_CODE.TRANSACTION_COMPLETED) {
        return context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
      }
      // do something with errror message
      handleErrorLoadingAccount(errorData);
    }
  }, [
    context?.paymentInfo?.configData?.collectionChannel,
    context?.paymentInfo?.paymentData?.apiKey,
    context?.paymentInfo?.configData?.apiUrl,
    params.id,
    listenForPaymentOnSocket,
  ]);

  useEffect(() => {
    const renderBankTransactionDetail = () => {
      const { bankTransferData } = context.paymentInfo?.paymentData || {};
      if (bankTransferData) {
        const { validity, validityBalance } = bankTransferData;
        if (validityBalance < validity) {
          const { accountNumber, accountName, bankName } = bankTransferData;
          setAccountNumber(accountNumber);
          setAccountName(accountName);
          setBank(bankName);
          setIsAccountDetailsDisplayed(true);

          context.updatePaymentData({
            paymentData: {
              ...context?.paymentInfo?.paymentData,
              bankTransferData: {
                ...context?.paymentInfo?.paymentData?.bankTransferData,
                validity: validity - validityBalance,
                validityBalance: 0,
              },
            },
          });
        } else {
          listenForPaymentOnSocket();
          return context.changeTransactionStage(
            APP_STAGE.TRANSACTION_FAILED,
            `This account number has exceeded expected payment duration and can’t be used anymore.`,
            "Account Expired!",
            [
              {
                text: PAY_WITH_OTHER_METHODS,
                onClickHanlder: () => {
                  TransactionService.switchNextPaymentMethod(
                    context,
                    constants.PAY_WITH_ACCOUNT_TRANSFER
                  );
                },
              },
            ]
          );
        }

        listenForPaymentOnSocket();
      } else {
        initializeTransaction();
      }
    };

    renderBankTransactionDetail();
  }, [initializeTransaction]);
  const [checkingPayment, setCheckingPayment] = useState(false);

  const handleCheckPayment = async (isButtonClick = false) => {
    try {
      if (checkingPayment) return;
      if (isButtonClick) setCheckingPayment(true);

      const response = await queryTransactionStatus();

      setCheckingPayment(false);
      if (isButtonClick && isTransactionPending(response.transactionStatus)) {
        setIsAccountDetailsDisplayed(false);
        setPaymentSessionStatus(response.transactionStatus);
      }
      if (
        response.responseBody.paymentSessionStatus &&
        !isPaymentSessionPending(response.responseBody.paymentSessionStatus)
      ) {
        setPaymentSessionStatus(response.responseBody.paymentSessionStatus);
        setIsAccountDetailsDisplayed(false);
      }
      if (response.responseBody.paymentStatus) {
        const isTestEnv =
          app_environment === "test" || app_environment === "prod";

        // Function to handle payment status update
        const updatePaymentStatus = () => {
          setPaymentStatus(response.responseBody.paymentStatus);
          setRejectionReason(response.responseBody?.rejectionReason);
        };

        // Function to handle transaction check
        const checkTransactionStatus = () => {
          if (isTransactionSuccessful(response.responseBody.paymentStatus)) {
            setMessageReceived(true);
            stopPollingPaymentStatus();

            const completeTransaction = () => {
              onComplete(
                response.responseBody.paymentStatus,
                response.responseBody
              );
            };

            isTestEnv
              ? setTimeout(completeTransaction, 2000)
              : completeTransaction();
          } else if (
            isTransactionRejected(response.responseBody.paymentStatus)
          ) {
            setMessageReceived(true);
            stopPollingPaymentStatus();

            const rejectTransaction = () => {
              onReject(
                response.responseBody.paymentStatus,
                response.responseBody
              );
            };

            isTestEnv
              ? setTimeout(rejectTransaction, 2000)
              : rejectTransaction();
          } else if (
            isTransactionExpired(response.responseBody.paymentStatus)
          ) {
            stopPollingPaymentStatus();
            context.setPaymentCompleteInfo(response.responseBody);
            context.changeTransactionStage(APP_STAGE.TRANSACTION_FAILED);
          }
        };

        // Execute with or without timeouts based on environment
        if (isTestEnv) {
          setTimeout(updatePaymentStatus, 2000);
          setTimeout(checkTransactionStatus, 2000);
        } else {
          updatePaymentStatus();
          checkTransactionStatus();
        }
      }
    } catch (error) {
      setCheckingPayment(false);
    }
  };

  const startPollingPaymentStatus = async (client) => {
    if (!messageReceived) {
      const response = await queryTransactionStatus();
      if (isTransactionExpired(response.responseBody.paymentStatus)) {
        stopPollingPaymentStatus();
        return;
      }

      if (!paymentStatusIntervalIdRef.current) {
        paymentStatusIntervalIdRef.current = setInterval(() => {
          handleCheckPayment();
        }, PAYMENT_STATUS_CONFIG.poll);
      }
    }
  };

  const stopPollingPaymentStatus = () => {
    TransactionService.source.cancel();

    if (paymentStatusIntervalIdRef.current) {
      clearInterval(paymentStatusIntervalIdRef.current);
    }
  };

  const queryTransactionStatus = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await TransactionService.queryTransactionStatus(
          context?.paymentInfo?.paymentData?.transactionReference,
          context?.paymentInfo?.paymentData?.apiKey,
          context?.paymentInfo?.configData?.apiUrl,
          true
        );
        let responseData = (response && response.data) || {};
        let responseBody = responseData.responseBody;
        let transactionStatus =
          responseBody.paymentStatus || TRANSACTION_STATUS.EXPIRED;
        resolve({ responseBody, transactionStatus });
      } catch (error) {
        console.log("error> ", error);
        let errorData = (error && error.response && error.response.data) || {};
        reject(errorData);
      }
    });
  };

  const onSocketMessageReceived = (message) => {
    if (!message) {
      return;
    }
    if (
      message.paymentSessionStatus &&
      !isPaymentSessionPending(message.paymentSessionStatus)
    ) {
      setPaymentSessionStatus(message.paymentSessionStatus);
      setIsAccountDetailsDisplayed(false);
    }
    if (message.paymentStatus) {
      const isTestEnv =
        context.getEnvironment() === "test" ||
        context.getEnvironment() === "prod";
      setMessageReceived(true);

      const updateInitialStatus = () => {
        setPaymentStatus(message.paymentStatus);
        setRejectionReason(message?.rejectionReason);
      };

      const handleTransactionStatus = () => {
        if (isTransactionSuccessful(message.paymentStatus)) {
          const handleComplete = () => {
            onComplete(message.paymentStatus, message);
          };

          isTestEnv ? setTimeout(handleComplete, 2000) : handleComplete();
        } else if (isTransactionRejected(message.paymentStatus)) {
          const handleReject = () => {
            onReject(message.paymentStatus, message);
          };

          isTestEnv ? setTimeout(handleReject, 2000) : handleReject();
        } else if (isTransactionExpired(message.paymentStatus)) {
          context.setPaymentCompleteInfo(message);
          context.changeTransactionStage(APP_STAGE.TRANSACTION_FAILED);
        }
      };

      if (isTestEnv) {
        setTimeout(updateInitialStatus, 2000);
        setTimeout(handleTransactionStatus, 2000);
      } else {
        updateInitialStatus();
        handleTransactionStatus();
      }
    }
  };

  const getPaymentActionText = () => {
    return "I’ve transferred the money";
  };

  const onComplete = (status, data) => {
    context.setPaymentCompleteInfo(data);
    context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
  };

  const onReject = (status, data) => {
    context.setPaymentCompleteInfo(data);
    context.changeTransactionStage(APP_STAGE.TRANSACTION_REJECTED);
  };

  const onTimerComplete = useCallback(() => {
    context.updatePaymentData({
      paymentData: {
        ...context?.paymentInfo?.paymentData,
        bankTransferData: null,
      },
    });
    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      `This account number has exceeded expected payment duration and can’t be used anymore.`,
      "Account Expired!",
      [
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_ACCOUNT_TRANSFER
            );
          },
        },
      ]
    );
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    () => {
      return TransactionService.source.cancel("cancelled");
    };
  }, []);

  useEffect(() => {
    const socketClient = socketClientRef.current;

    return () => {
      if (socketClient) {
        socketClient.disconnect();
        stopPollingPaymentStatus();
      }
    };
  }, []);

  const handleWebsocketConnect = () => {
    setIsWebsocketConnected(true);

    stopPollingPaymentStatus();
  };

  const handleWebsocketDisconnect = () => {
    setIsWebsocketConnected(false);

    if (!messageReceived) {
      startPollingPaymentStatus(socketClientRef.current);
    }
  };

  const handleWebsocketConnectFailure = (error) => {
    setIsWebsocketConnected(false);

    if (!messageReceived) {
      startPollingPaymentStatus(socketClientRef.current);
    }
  };

  return (
    <>
      {loading && !loadingFailed && <Loader />}

      {!loadingFailed && !loading && (
        <Fade>
          <div className={classNames("container-fluid")}>
            <div
              className={classNames(
                "pay-with-card-content-wrapper",
                props.className
              )}
            >
              {(paymentSessionStatus === null || isAccountDetailsDisplayed) && (
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="text-center sub-title text-black lh-2 font-inter f-w-500">
                      Transfer to account details below
                    </h3>
                  </div>
                </div>
              )}
              {showError && (
                <Fade top>
                  <Banner type={ERROR_BANNER} />
                </Fade>
              )}

              <div className="tranfer-blue-light-bg">
                {paymentSessionStatus === null || isAccountDetailsDisplayed ? (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <h2 className="h-sub-title text-center ussd-bank-name ls-1 mb-0">
                          {bank}
                        </h2>
                        <div className="d-flex flex-column align-items-center mb-1">
                          <div className="d-flex justify-content-center align-item-end">
                            <h2 className="h-title mb-0 text-center font-inter text-primary-blue ls-1 f-16">
                              {accountNumber}
                            </h2>
                            <ClipBoard text={accountNumber} />
                          </div>
                          <div className="info-banner info-banner-small">
                            <h6 className="text-center sub-title lh-2 font-inter f-w-500">
                              Do not save this account no.!
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-12">
                        <h4 className="h-sub-title  text-color-grey text-center">
                          Account Name
                        </h4>

                        <h2 className="text-center f-s-12 ls-0 bank-name font-bold font-inter">
                          {accountName}
                        </h2>
                      </div>
                    </div>
                  </>
                ) : (
                  <PaymentProgress
                    paymentSessionStatus={paymentSessionStatus}
                    paymentStatus={paymentStatus}
                    rejectionReason={rejectionReason}
                  />
                )}
                <div className="py-1"></div>
                <ProgressBar2
                  countDown={validatyTime}
                  onTimerComplete={onTimerComplete}
                />
              </div>
              <div className="m-y-12"></div>
              <PaymentOptonTrail
                banks={banks}
                amount={context?.paymentInfo?.paymentData?.amount}
                accountNumber={accountNumber}
                isAccountDetailsDisplayed={isAccountDetailsDisplayed}
                setIsAccountDetailsDisplayed={setIsAccountDetailsDisplayed}
              />
              {(paymentSessionStatus === null || isAccountDetailsDisplayed) && (
                <Button
                  onClick={() => {
                    handleCheckPayment(true);
                  }}
                  style={{ padding: checkingPayment ? "0px 8px" : "10px 8px" }}
                >
                  {getPaymentActionText()}{" "}
                  {checkingPayment && (
                    <span>
                      <MonnifyIcons type={SPINNER_WHITE} />
                    </span>
                  )}
                </Button>
              )}
            </div>
          </div>
        </Fade>
      )}
      {listenForPayment && (
        <SockJsClient
          url={socketUrl}
          topics={socketTopics}
          onMessage={onSocketMessageReceived}
          ref={socketClientRef}
          autoReconnect={true}
          reconnectInterval={RECONNECT_INTERVAL}
          onConnect={handleWebsocketConnect}
          onDisconnect={handleWebsocketDisconnect}
          onConnectFailure={handleWebsocketConnectFailure}
        />
      )}
    </>
  );
};

export const Banner = ({ type, text }) => {
  const Error = () => {
    return (
      <div className="monnify-banner">
        <div className="monnify-error-banner">
          <span>
            <MonnifyIcons
              style={{ display: "flex", alignItem: "center" }}
              type={ERROR_INFO}
            />
          </span>
          <article>
            {text ||
              "Transfer not received yet. Please wait while we confirm payment."}
          </article>
        </div>
      </div>
    );
  };

  const getBannerType = () => {
    switch (type) {
      case ERROR_BANNER:
        return <Error />;
      default:
        return null;
    }
  };
  return getBannerType();
};

const PaymentOptonTrail = ({
  banks,
  amount,
  accountNumber,
  isAccountDetailsDisplayed,
  setIsAccountDetailsDisplayed,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ussdTemplate, setTemplate] = useState(null);
  const [bankCode, setBankCode] = useState("");
  const handleOnBankChange = (e) => {
    const bankCode = e.target.value;
    setBankCode(bankCode);
    const bank = banks.find((item) => item.code === bankCode) || {};
    const template = bank?.ussdTemplate
      ?.replace("AccountNumber", accountNumber)
      .replace("Amount", amount);
    setTemplate(template);
  };

  const getFontSize = () => {
    return ussdTemplate.length > 22 ? "font-small" : "";
  };

  const toggleState = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    if (!isOpen) {
      setTemplate(null);
      setBankCode("");
    }
  }, [isOpen]);

  return (
    <div className="animate__animated animate__fadeIn">
      {!isAccountDetailsDisplayed ? (
        <h2
          onClick={() => {
            setIsAccountDetailsDisplayed(true);
          }}
          className="h-title f-w-500 mb-0 text-primary-blue f-s-12 fnt-medium cursor-pointer text-center"
        >
          See account details
        </h2>
      ) : (
        <h2
          onClick={toggleState}
          className="h-title f-w-500 mb-0 text-primary-blue f-s-12 fnt-medium cursor-pointer text-center"
        >
          {isOpen ? "Close USSD code" : "Get your bank's USSD code"}
        </h2>
      )}

      <hr className="m-y-12 border-grey" />
      {isOpen ? (
        <Fade>
          <>
            <Select
              onSelect={handleOnBankChange}
              placeholder={"Select Bank"}
              options={banks.map((item) => ({
                label: item.name,
                value: item.code,
              }))}
              value={bankCode}
            />
            {banks.length > 0 && ussdTemplate && (
              <Fade top>
                <div className="py-2"></div>
                <h1 className="h-sub-title text-center">Dial the code </h1>
                <div className="ussd-code-container ls-1">
                  <h3 className={getFontSize()}>{ussdTemplate}</h3>
                </div>
                <div className="py-2"></div>
                <div className="d-flex justify-content-center align-items-center">
                  <ClipBoard
                    text={ussdTemplate}
                    defaultComponent={
                      <h1 className="h-sub-title text-center cursor-pointer text-grey">
                        Click to Copy
                      </h1>
                    }
                  />
                </div>
                <div className="py-3"></div>
              </Fade>
            )}
          </>
        </Fade>
      ) : null}
    </div>
  );
};

export default BankTranfer;
