import MonnifyPaymentData from "./data.service";
import Axios from "axios";
import * as Currency from "currency.js";
import constants from "../constants";
import { PAYMENT_METHODS, PAY_WITH_OTHER_METHODS } from "./constants";
import { STAGES as APP_STAGE } from "../App";
import {
  isTransactionCompleted,
  isTransactionExpired,
  isTransactionFailed,
  isTransactionSuccessful,
} from "./transaction-status.constant";

class TransactionService {
  static source = Axios.CancelToken.source();
  static controller = new AbortController();
  static cancelTransaction = (apiUrl) => {
    if (!MonnifyPaymentData || !MonnifyPaymentData.transactionReference) {
      return;
    }

    let url = `${apiUrl}/api/v1/sdk/bank-transfer/cancel-transaction/${MonnifyPaymentData.apiKey}/${MonnifyPaymentData.transactionReference}`;
    return Axios.put(url, {});
  };

  static switchNextPaymentMethod(context, activePaymentMethod) {
    // This determines the payment methods available to the user frontend
    const USER_PAYMENT_METHODS = [
      PAYMENT_METHODS.ACCOUNT_TRANSFER,
      PAYMENT_METHODS.CARD,
      PAYMENT_METHODS.USSD,
      PAYMENT_METHODS.PHONE_NUMBER,
    ];
    const allPaymentMethods =
      context?.paymentInfo?.paymentData?.enabledPaymentMethods || [];
    let index = 0;

    //exclude   CASH AND NQR

    // const enabledPaymentMethods = ALL_PAYMENT_METHODS.filter(
    //   (item) => item !== "CASH" && item !== "NQR"
    // ).filter((item) => USER_PAYMENT_METHODS.includes(item));

    const enabledPaymentMethods = USER_PAYMENT_METHODS.filter((item) =>
      allPaymentMethods.includes(item)
    );

    switch (activePaymentMethod) {
      case constants.PAY_WITH_CARD:
        index = enabledPaymentMethods.indexOf(PAYMENT_METHODS.CARD);
        break;
      case constants.PAY_WITH_ACCOUNT_TRANSFER:
        index = enabledPaymentMethods.indexOf(PAYMENT_METHODS.ACCOUNT_TRANSFER);
        break;
      case constants.PAY_WITH_USSD:
        index = enabledPaymentMethods.indexOf(PAYMENT_METHODS.USSD);
        break;
      case constants.PAY_WITH_BANK:
        index = enabledPaymentMethods.indexOf(PAYMENT_METHODS.DIRECT_DEBIT);
        break;
      case constants.PAY_WITH_PHONE_NO:
        index = enabledPaymentMethods.indexOf(PAYMENT_METHODS.PHONE_NUMBER);
        break;
      default:
        return null;
    }

    const nextPaymentMethod =
      enabledPaymentMethods[(index + 1) % enabledPaymentMethods.length];

    switch (nextPaymentMethod) {
      case PAYMENT_METHODS.CARD:
        context.updateActiveLink(constants.PAY_WITH_CARD);

        break;
      case PAYMENT_METHODS.DIRECT_DEBIT:
        context.updateActiveLink(constants.PAY_WITH_BANK);
        break;
      case PAYMENT_METHODS.ACCOUNT_TRANSFER:
        context.updateActiveLink(constants.PAY_WITH_ACCOUNT_TRANSFER);
        break;
      case PAYMENT_METHODS.USSD:
        context.updateActiveLink(constants.PAY_WITH_USSD);
        break;
      case PAYMENT_METHODS.PHONE_NUMBER:
        context.updateActiveLink(constants.PAY_WITH_PHONE_NO);
        break;
      default:
        return null;
    }

    context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
  }

  static initializeTransaction = (paymentConfig) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          "/app/transaction/init",
          paymentConfig
        );
        console.log("called successll");
        resolve({ success: true, data: response.data });
      } catch (error) {
        reject(error);
      }
    });
  };

  static initializeBankTransaction = (paymentConfig, apiUrl) => {
    return Axios.post(
      `${apiUrl}/api/v1/sdk/bank/init-transaction`,
      paymentConfig
    );
  };
  static verifyBankTransaction = (paymentConfig, apiUrl) => {
    return Axios.post(`${apiUrl}/api/v1/sdk/bank/verify`, paymentConfig);
  };

  static getPayWithBankSupportedBanks = (apiUrl) => {
    return Axios.get(`${apiUrl}/api/v1/sdk/bank/list-banks`);
  };

  static initiateBankCharge = (paymentConfig, apiUrl) => {
    return Axios.post(`${apiUrl}/api/v1/sdk/bank/charge`, paymentConfig);
  };

  static authorizeBankCharge = (paymentConfig, apiUrl) => {
    return Axios.post(`${apiUrl}/api/v1/sdk/bank/authorize/otp`, paymentConfig);
  };

  static initializeTransactionWithReference = (transactionRef) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(`/app/checkout/${transactionRef}`);

        resolve({ success: true, data: response.data });
      } catch (error) {
        reject(error);
      }
    });
  };

  static getDateDifferenceInSeconds = (date) => {
    const date1 = new Date();
    const date2 = new Date(date);

    const differenceInMilliseconds = date2 - date1;

    return differenceInMilliseconds < 0 ? 0 : differenceInMilliseconds / 1000;
  };

  static processIntializePaymentDeviceParams = (transactionRef) => {
    return Axios.put(
      `/app/v1/sdk/transactions/init-payment/${transactionRef}`,
      {
        otherPaymentData: {
          httpBrowserLanguage: "en-US",
          httpBrowserJavaEnabled: false,
          httpBrowserJavaScriptEnabled: "true",
          httpBrowserColorDepth: "24",
          httpBrowserScreenHeight: window.innerHeight,
          httpBrowserScreenWidth: window.innerWidth,
          httpBrowserTimeDifference: "",
          userAgentBrowserValue: navigator.userAgent,
        },
      }
    );
  };

  static queryTransactionStatus = (
    reference,
    apiKey,
    apiUrl,
    paymentSessionInfo = false
  ) => {
    let url = `${apiUrl}/api/v1/sdk/transactions/query/${apiKey}?transactionReference=${encodeURIComponent(
      reference
    )}&shouldIncludePaymentSessionInfo=${paymentSessionInfo}`;
    return Axios.get(url, { signal: TransactionService.controller.signal });
  };

  static cancelAllRequest() {
    TransactionService.controller.abort();
    TransactionService.controller = new AbortController();
  }

  static cancelRequestOnCompleted() {
    TransactionService.controller.abort();
  }

  static queryDirectDebitTransactionStatus = (
    transactionReference,
    providerReference,
    collectionChannel,
    apiUrl,
    source
  ) => {
    let url = `${apiUrl}/api/v1/sdk/bank/transaction-status`;
    return Axios.post(url, {
      transactionReference,
      providerReference,
      collectionChannel,
      signal: TransactionService.controller.signal,
    });
  };

  static queryPhoneNumberTransactionStatus = (reference, apiKey, apiUrl) => {
    let url = `${apiUrl}/api/v1/sdk/phone-payment/transaction-status/${encodeURIComponent(
      reference
    )}`;
    return Axios.get(url, {
      timeout: 120000,
      signal: TransactionService.controller.signal,
    });
  };

  static queryUSSDTransactionStatus = (reference, apiUrl) => {
    let url = `${apiUrl}/api/v1/sdk/ussd/transaction-status/${encodeURIComponent(
      reference
    )}`;
    return Axios.get(url, { signal: TransactionService.controller.signal });
  };

  static chargeCard = (request, apiUrl) => {
    let url = `${apiUrl}/api/v1/sdk/cards/charge`;
    return Axios.post(url, request);
  };

  static authorizeOtp = (request, apiUrl) => {
    // let url = `${apiUrl ? apiUrl : MonnifyConfig.apiUrl}/api/v1/sdk/cards/otp/authorize`;
    let url = `${apiUrl}/api/v1/sdk/cards/otp/authorize`;
    return Axios.post(url, request);
  };

  static getCardRequirements = (payload) => {
    let url = `${payload.domain}/api/v1/sdk/cards/requirements`;
    return Axios.post(url, {
      pan: payload.cardNumber,
      transactionReference: payload.transactionReference,
      collectionChannel: payload.collectionChannel,
    });
  };

  static getCardTransactionStatus = (url, transRef) => {
    transRef = encodeURIComponent(transRef);
    return Axios.get(
      `${url}/api/v1/sdk/cards/card-transaction-status/${transRef}`,
      { signal: TransactionService.controller.signal }
    );
  };

  static getTransactionFee = (apiUrl, request) => {
    const url = `${apiUrl}/api/v1/sdk/transactions/${encodeURIComponent(
      request.transactionReference
    )}/payment-details`;
    return Axios.post(url, request);
  };

  static initializeBankTransfer = (request, apiUrl) => {
    let url = `${apiUrl}/api/v1/sdk/bank-transfer/init-payment`;
    return Axios.post(url, request);
  };
  static initializePhoneTransaction = (request, apiUrl) => {
    let url = `${apiUrl}/api/v1/sdk/phone-payment/initialize`;
    return Axios.post(url, request);
  };
  static initialPhoneTransaction = (request, apiUrl) => {
    let url = `${apiUrl}/api/v1/sdk/phone-payment/initialize`;
    return Axios.post(url, request);
  };
  static initializeUSSDTransfer = (request, apiUrl) => {
    let url = `${apiUrl}/api/v1/sdk/ussd/initialize`;
    return Axios.post(url, request);
  };
  static initializeCardTransaction = (request, apiUrl) => {
    const url = `${apiUrl}/api/v1/sdk/cards/init-card-transaction`;
    return Axios.post(url, request);
  };

  static getBanks = (apiUrl) => {
    let url = `${apiUrl}/api/v1/sdk/transactions/banks`;
    return Axios.get(url);
  };

  static formatAmount = (symbol, amount) => {
    symbol = symbol || "₦";

    let currencyOption = {
      symbol,
    };

    let currency = Currency(amount, currencyOption);
    return currency.format(true);
  };

  static authorizeSecure3d = (request, apiUrl) => {
    let url = `${apiUrl}/api/v1/sdk/cards/secure-3d/authorize`;
    return Axios.post(url, request);
  };

  static formatAsMoney(x) {
    if (isNaN(x)) return TransactionService.formatAmount("₦", 0);
    const formattedCurrency = TransactionService.formatAmount("₦", x);

    return formattedCurrency;
  }

  static handleTransactionResponse(context, transactionStatus, data) {
    if (isTransactionFailed(transactionStatus)) {
      return context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        `Unable to use this payment option at the moment. Please try again shortly. If the issue persists, contact support.`,
        `Temporary Error`,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (isTransactionSuccessful(transactionStatus)) {
      context.setPaymentCompleteInfo(data);
      context.changeTransactionStage(APP_STAGE.TRANSACTION_SUCCESSFUL);
    } else if (isTransactionExpired(transactionStatus)) {
      return context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        data.message || data.responseMessage || "Transaction Expired",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (isTransactionCompleted(transactionStatus)) {
      return context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        data.message || data.responseMessage || "Transaction Completed",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                this.context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    } else if (isTransactionExpired(transactionStatus)) {
      return context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        data.message || data.responseMessage || "Transaction Pending",
        null,
        [
          {
            text: "Try again with Card",
            onClickHanlder: () => {
              context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
            },
          },
          {
            text: PAY_WITH_OTHER_METHODS,
            onClickHanlder: () => {
              TransactionService.switchNextPaymentMethod(
                context,
                constants.PAY_WITH_CARD
              );
            },
          },
        ]
      );
    }

    return context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      data.responseMessage || "Unable to complete transaction",
      "Transaction Failed",
      [
        {
          text: "Try again with Card",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              this.context,
              constants.PAY_WITH_CARD
            );
          },
        },
      ]
    );
  }
}

export default TransactionService;
