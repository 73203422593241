import React, { useEffect, useState } from "react";
import PaymentContainer from "./component/payment-container";
import StoreContext from "./services/store/store-context";
import StoreProvider from "./services/store/store.provider";
import "./styles/styles.scss";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Error from "./page/transaction-status/Error";
import { MESSAGE_TYPE } from "./services/constants";
import AppLoading from "./page/transaction-status/AppLoading";
import { GlobalDebug } from "./util/remove-console";
import "animate.css";
import MonnifyPopupService from "./services/popup.service";

export const STAGES = {
  TRANSACTION_PROCESSING: "TRANSACTION_PROCESSING",
  TRANSACTION_SUCCESSFUL: "TRANSACTION_SUCCESSFUL",
  TRANSACTION_REJECTED: "TRANSACTION_REJECTED",
  TRANSACTION_FAILED: "TRANSACTION_FAILED",
  LOADING_FAILED: "LOADING_FAILED",
};
function App() {
  const [paymentConfig, setPaymentConfig] = useState(null);
  const [canAppLoad, setCanAppLoad] = useState(false);
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      GlobalDebug(false, true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", (e) => {
      const message = e.data;
      MonnifyPopupService.isWebInitialized = true;
      if (message.type === MESSAGE_TYPE.INIT_POPUP) {
        //

        setCanAppLoad(true);
        setPaymentConfig(message.data);
      }
    });
    return () => {
      window.removeEventListener("message", (e) => {
        const message = e.data;
        if (message.type === MESSAGE_TYPE.INIT_POPUP) {
          //

          setPaymentConfig(message.data);
        }
      });
      setCanAppLoad(false);
    };
  }, []);

  return (
    <div className="body animate__animated ">
      <div className="body-app-wrapper animate__fadeInDown">
        <StoreProvider>
          <StoreContext.Consumer>
            {(context) => (
              <Router>
                <Routes>
                  <Route path="*" element={<Error />} />
                  <Route path="/">
                    <Route
                      index
                      element={
                        <AppLoading
                          canAppLoad={canAppLoad}
                          paymentData={paymentConfig}
                        />
                      }
                    />
                    <Route path="error" element={<Error />} />
                    <Route
                      path="checkout/:id"
                      element={<Home context={context} />}
                    ></Route>
                  </Route>
                </Routes>
              </Router>
            )}
          </StoreContext.Consumer>
        </StoreProvider>
      </div>
    </div>
  );
}

const Home = ({ context }) => {
  const showPaymentStage = (context) => {
    switch (context.stage) {
      default:
        return <PaymentContainer />;
    }
  };
  return showPaymentStage(context);
};

export default App;
