import React, { useCallback, useContext, useEffect } from "react";
import MonnifyIcons from "../../icon";
import { WARNING_ICON } from "../../icon/icon";
import { MESSAGE_TYPE } from "../../services/constants";
import MonnifyPopupService from "../../services/popup.service";
import StoreContext from "../../services/store/store-context";
import TransactionService from "../../services/transaction.service";
import {
  isTransactionUnderpaid,
  isTransactionOverpaid,
} from "../../services/transaction-status.constant";
import "./index.scss";
import { STAGES as APP_STAGE } from "../../App";
import constants from "../../constants";

const Rejected = () => {
  const context = useContext(StoreContext);
  const {
    rejectionComment,
    rejectionReason,
    merchantSupportEmail,
    amountPaid,
  } = context?.paymentCompleteInfo;

  const renderTryAgainButton = () => {
    const { bankTransferData } = context.paymentInfo?.paymentData || {};
    const { validity, validityBalance } = bankTransferData;

    if (bankTransferData && validityBalance < validity) {
      return (
        <div className="options">
          <ul>
            <li>
              <div onClick={handleOnTryAgainWithTransfer}>Try Again</div>
            </li>
          </ul>
        </div>
      );
    }
    return (
      <div className="options">
        <ul>
          <li>
            <div
              onClick={() => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_CARD
                );
              }}
            >
              Try again with other methods
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const handleOnTryAgainWithTransfer = () => {
    context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
  };

  const intiateSDKClose = useCallback(() => {
    setTimeout(() => {
      if (MonnifyPopupService.isWebInitialized)
        context.postMessage({
          type: MESSAGE_TYPE.TRANSACTION_FAILED,
          data: context?.paymentCompleteInfo,
        });

      if (context.getRedirectUrl()) {
        const redirectUrl = context.getRedirectUrl();
        window.location = redirectUrl.includes("?")
          ? redirectUrl +
            `&paymentReference=${context.paymentCompleteInfo.paymentReference}`
          : redirectUrl +
            `?paymentReference=${context.paymentCompleteInfo.paymentReference}`;
      }
    }, context.getCloseInterval());
  }, []);

  return (
    <div className="status-paymentContainer-wrapper">
      <div className="success-wrapper">
        <div className="icon pt-3">
          <MonnifyIcons type={WARNING_ICON} />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <h2 className="title text-center">Payment Rejected</h2>

              <article className="article pt-2">
                {isTransactionUnderpaid(rejectionReason) ? (
                  <>
                    <div>
                      <span>
                        Amount paid -{" "}
                        <b>
                          {TransactionService.formatAsMoney(amountPaid || 0)}
                        </b>{" "}
                        is below the expected payable amount. A refund is being
                        processed to the sender's account.
                      </span>
                      <p className="mt-3">
                        Please contact <b> {merchantSupportEmail}</b> for
                        inquires on refund’s status.
                      </p>
                    </div>
                    {renderTryAgainButton()}
                  </>
                ) : isTransactionOverpaid(rejectionReason) ? (
                  <>
                    <div>
                      <span>
                        Amount paid -{" "}
                        <b>
                          {TransactionService.formatAsMoney(amountPaid || 0)}{" "}
                        </b>
                        exceeds the expected payable amount. A refund is being
                        processed to the sender's account.
                      </span>
                      <p className="mt-3">
                        Please contact <b> {merchantSupportEmail}</b> for
                        inquires on refund’s status.
                      </p>
                    </div>
                    {renderTryAgainButton()}
                  </>
                ) : (
                  <div>
                    <span>
                      {rejectionComment}. The funds will be refunded to you
                      shortly.
                    </span>
                    <p className="mt-3">
                      If you are still having any issue contact{" "}
                      <b>{merchantSupportEmail}</b>
                    </p>
                  </div>
                )}
              </article>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rejected;
