import React from "react";
import {
  isTransactionSuccessful,
  isTransactionPending,
  isTransactionFailed,
  isTransactionRejected,
  isPaymentSessionFailed,
  isTransactionOverpaid,
  isTransactionUnderpaid,
  isPaymentSessionPending,
  isPaymentExpired
} from "../../services/transaction-status.constant";
import { Progress } from 'antd';
import MonnifyIcons from "../../icon";
import { SENT_ICON, CONFIRM_ICON, REJECTED_ICON } from "../../icon/icon";


const PaymentProgress = ({ paymentStatus, paymentSessionStatus, rejectionReason }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center py-4">
      <div className="payment-status-header">
      <span>
        {(() => {
          if (isPaymentSessionPending(paymentSessionStatus)) {
            return "Awaiting Transfer";
          } else if (isTransactionSuccessful(paymentStatus)) {
            return "Transfer Successful";
          } else if (isTransactionRejected(paymentStatus) && isTransactionUnderpaid(rejectionReason)) {
            return "Underpaid Amount";
          } else if (isTransactionRejected(paymentStatus) && isTransactionOverpaid(rejectionReason)) {
            return "Overpaid Amount";
          } else if (isTransactionRejected(paymentStatus) && !isTransactionUnderpaid(rejectionReason) && !isTransactionOverpaid(rejectionReason)) {
            return "Transfer Rejected";
          } else if (isTransactionFailed(paymentStatus) || isPaymentSessionFailed(paymentSessionStatus)) {
            return "Transfer Failed";
          } else if (isPaymentSessionPending(paymentSessionStatus) && isPaymentExpired(paymentStatus)) {
            return "Transaction Expired";
          } else {
            return "Awaiting Confirmation"
          }
        })()}
      </span>
      </div>
      <div className="d-flex justify-content-between align-items-start payment-status-progress-container">
        <div className="d-flex flex-column align-items-center payment-created">
          <span>
            <MonnifyIcons
              className="align-icons"
              type={
                (() => {
                  if (isPaymentSessionPending(paymentSessionStatus)) {
                    return CONFIRM_ICON;
                  } else {
                    return SENT_ICON;
                  } 
                })()
              }
            />
          </span>
          <span className="payment-status">Received</span>
        </div>

        <div className="d-flex justify-content-between progress-bar-container">
          <div className='progress-bars'>
            <Progress
              percent={isPaymentSessionPending(paymentSessionStatus) && !isPaymentExpired(paymentStatus) ? 0 : 100}
              status="active"
              strokeColor="rgba(30, 177, 45, 1)"
              showInfo={false}
              className={isPaymentSessionPending(paymentSessionStatus) ? 'loading-progress' : ''}
            />         
          </div>
          <div className='progress-bars'>
            <Progress
              percent={(paymentStatus === null || isTransactionPending(paymentStatus)) ? 0 : 100}
              strokeColor={
                (isTransactionSuccessful(paymentStatus)) ?
                  "rgba(30, 177, 45, 1)" :
                  "rgba(233, 68, 68, 1)"
              }
              status="active"
              showInfo={false}
              className={(!isPaymentSessionPending(paymentSessionStatus) && (paymentStatus === null || isTransactionPending(paymentStatus))) ? 'loading-progress' : ''}
            />
          </div>
        </div>

        <div className="d-flex flex-column align-items-center payment-confirmed">
          <span>
            <MonnifyIcons
              className="align-icons"
              type={
                (() => {
                  if (isTransactionPending(paymentStatus) || paymentStatus === null) {
                    return CONFIRM_ICON;
                  } else if (isTransactionSuccessful(paymentStatus)) {
                    return SENT_ICON;
                  } else if (isTransactionRejected(paymentStatus)) {
                    return REJECTED_ICON;
                  } else {
                    return REJECTED_ICON;
                  }
                })()
              }
            />
          </span>
          <span className="payment-status">
            {(() => {
              if (isTransactionSuccessful(paymentStatus) || isTransactionPending(paymentStatus) || paymentStatus === null) {
                return "Confirmed";
              } else if (isTransactionRejected(paymentStatus)) {
                return "Rejected";
              } else if (isTransactionFailed(paymentStatus)) {
                return "Failed"
              } else if(isPaymentExpired(paymentStatus)){
                return "Expired"
              } else {
                return ""
              }
            })()}
          </span>
        </div>
      </div>
    </div>

  );
};

export default PaymentProgress;
