import React, { useCallback, useContext, useEffect, useState } from "react";
import StoreContext from "../../services/store/store-context";

const ProgressBar2 = ({ countDown, onTimerComplete }) => {
  const [mins, setMins] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [totalValidity, setTotalValidty] = useState(0);
  const [availableValidity, setAvailableValidity] = useState(0);
  const context = useContext(StoreContext);

  useEffect(() => {
    const { validity } =
      context.paymentInfo?.paymentData?.bankTransferData || {};
    setTotalValidty(validity || 0);
  }, [context]);
  useEffect(() => {
    const { validityBalance } =
      context.paymentInfo?.paymentData?.bankTransferData || {};

    if (totalValidity !== 0 && validityBalance >= totalValidity) {
      onTimerComplete();
    }

    setAvailableValidity(validityBalance || 0);
  }, [context]);

  useEffect(() => {
    const difference = totalValidity - availableValidity;

    const mins = Math.floor(difference / 60);
    const secs = difference % 60;

    setMins(mins);
    setSeconds(secs);
  }, [availableValidity, totalValidity]);

  return (
    <div className="row">
      <div className="progressbar-container2">
        <div className="overlay"></div>
        <div
          style={{ width: `${(availableValidity / totalValidity) * 100}%` }}
          className="progress"
        ></div>
      </div>
      <div className="py-1"></div>
      <div className="d-flex progress-amount-desc-container">
        {availableValidity >= totalValidity ? (
          <span className="counter-text">Expired!</span>
        ) : (
          <>
            <h5 className="counter-text">This one-time account expires in</h5>
            <h5 className="amount-bold counter-text">
              {mins} {mins > 1 ? "mins" : "min"} {seconds}
              {seconds > 1 ? " secs" : " sec"}
            </h5>
          </>
        )}
      </div>
    </div>
  );
};

//(minutesBalLocal / totalMinutes) * 100

export default ProgressBar2;
