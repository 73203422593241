import { useCallback, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import MonnifyIcons from "../../icon";
import {
  MESSAGE_TYPE,
  PAYMENT_METHODS,
  POLLING_DELAY_INTERVAL,
  POLLING_START_DELAY,
} from "../../services/constants";
import Content from "../content";
import "./index.scss";
import StoreContext from "../../services/store/store-context";
import TransactionService from "../../services/transaction.service";
import { CANCEL_ICON, PADLOCK_ICON } from "../../icon/icon";
import { STAGES as APP_STAGE } from "../../App";
import constants from "../../constants";
import { isTestEnvironment } from "../../services/config.service";
import { Zoom } from "react-reveal";
import MonnifyPopupService from "../../services/popup.service";
import {
  isTransactionSuccessful,
  isTransactionCancelled,
  isTransactionExpired,
  isTransactionRejected,
} from "../../services/transaction-status.constant";
import useCountDown from "../../hooks/useCountDown";

const PaymentContainer = () => {
  const context = useContext(StoreContext);
  const params = useParams();
  const pollingInTervalId = useRef();

  useCountDown();

  const closePopup = () => {
    if (context.getRedirectUrl()) {
      const redirectUrl = context.getRedirectUrl();
      window.location = redirectUrl.includes("?")
        ? redirectUrl +
          `&paymentReference=${context?.paymentInfo?.paymentData?.paymentReference}`
        : redirectUrl +
          `?paymentReference=${context?.paymentInfo?.paymentData?.paymentReference}`;
    } else if (window.parent && window.parent.postMessage) {
      context.postMessage({ type: MESSAGE_TYPE.CANCEL, data: null }, "*");
    } else {
      window.close();
    }
  };

  const getRef = useCallback(async () => {
    try {
      if (!context || !context.paymentInfo) {
        const response =
          await TransactionService.initializeTransactionWithReference(
            params.id
          );

        const ressponseData = response.data.responseData || {};
        context.updatePaymentData(response.data.data);

        if (
          isTransactionSuccessful(
            ressponseData.responseBody?.transactionRecord?.paymentStatus
          )
        ) {
          return context.changeTransactionStage(
            APP_STAGE.TRANSACTION_SUCCESSFUL
          );
        } else if (
          isTransactionCancelled(
            ressponseData.responseBody?.transactionRecord?.paymentStatus
          )
        ) {
          return context.changeTransactionStage(
            APP_STAGE.TRANSACTION_FAILED,
            "Transaction has been cancelled"
          );
        } else if (
          isTransactionExpired(
            ressponseData.responseBody?.transactionRecord?.paymentStatus
          )
        ) {
          return context.changeTransactionStage(
            APP_STAGE.TRANSACTION_FAILED,
            "This transaction has exceeded expected payment duration and can’t be paid for anymore. Please re-initiate another transaction to complete your payment.",
            "Transaction Expired"
          );
        } else {
          await TransactionService.processIntializePaymentDeviceParams(
            params.id
          );
          setEnvironment(response.data.data);

          setDefaultActiveMenut(response.data.data);
        }
      } else {
      }
    } catch (error) {
      console.log("errpor> ", error?.response);
      TransactionService.controller.abort();

      context.changeTransactionStage(
        APP_STAGE.TRANSACTION_FAILED,
        error?.response?.data?.errorData?.responseMessage
      );
    }
  }, [params.id]);

  useEffect(() => {
    getRef();
  }, [getRef]);

  const pollingService = useCallback(() => {
    if (!context.getAPIKey()) return stopPolling();
    if (!pollingInTervalId.current) {
      pollingInTervalId.current = setInterval(() => {
        TransactionService.queryTransactionStatus(
          params.id,
          context.getAPIKey(),
          context.getAppURL(),
          true
        )
          .then((response) => {
            const transactionStatus = response.data.responseBody.paymentStatus;
            const data = response.data.responseBody;
            if (isTransactionExpired(transactionStatus)) {
              context.setPaymentCompleteInfo(data);
              context.changeTransactionStage(
                APP_STAGE.TRANSACTION_FAILED,
                "This transaction has exceeded expected payment duration and can’t be paid for anymore. Please re-initiate another transaction to complete your payment.",
                "Transaction Expired!"
              );
              return stopPolling();
            }
          })
          .catch((error) => {
            const errorData =
              (error && error.response && error.response.data) || {};
            //  const transactionStatus = errorData?.responseBody?.status;
          });
      }, POLLING_DELAY_INTERVAL);
    }
  }, [context.getAPIKey()]);

  const startPollingService = useCallback(() => {
    setTimeout(() => {
      pollingService();
    }, POLLING_START_DELAY);
  }, [pollingService]);

  const stopPolling = useCallback(() => {
    if (pollingInTervalId.current) {
      TransactionService.source.cancel();
      clearInterval(pollingInTervalId.current);
    }
  }, []);

  useEffect(() => {
    startPollingService();
  }, [startPollingService]);

  const setEnvironment = (payload) => {
    if (isTestEnvironment(payload?.paymentData)) {
      return context.updateTestMode(true);
    }
    return context.updateTestMode(false);
  };

  const setDefaultActiveMenut = (data) => {
    const enablePaymentMethods = data?.paymentData?.enabledPaymentMethods || [];

    if (enablePaymentMethods.includes(PAYMENT_METHODS.ACCOUNT_TRANSFER)) {
      return context.updateActiveLink(constants.PAY_WITH_ACCOUNT_TRANSFER);
    } else if (enablePaymentMethods.includes(PAYMENT_METHODS.CARD)) {
      return context.updateActiveLink(constants.PAY_WITH_CARD);
    } else if (enablePaymentMethods.includes(PAYMENT_METHODS.USSD)) {
      return context.updateActiveLink(constants.PAY_WITH_USSD);
    } else if (enablePaymentMethods.includes(PAYMENT_METHODS.PHONE_NUMBER)) {
      return context.updateActiveLink(constants.PAY_WITH_PHONE_NO);
    } else if (enablePaymentMethods.includes(PAYMENT_METHODS.DIRECT_DEBIT)) {
      return context.updateActiveLink(constants.PAY_WITH_BANK);
    }
  };

  return (
    <Zoom>
      <div className="paymentContainer-wrapper">
        {MonnifyPopupService.isWebInitialized || context.getRedirectUrl() ? (
          <div className="top-menu">
            <div onClick={closePopup}>
              <h2>
                CLOSE{" "}
                <span>
                  <MonnifyIcons type={CANCEL_ICON} />
                </span>
              </h2>
            </div>
          </div>
        ) : null}

        <div className="bottom-menu">
          <div style={{ paddingBottom: 8 }}>
            <MonnifyIcons type={PADLOCK_ICON} />
          </div>

          <div style={{ display: "flex", paddingBottom: 3 }}>
            <h2 style={{ paddingLeft: 3, paddingRight: 6 }}>
              Secured by <span className="monnify-text">monnify</span>
            </h2>
          </div>
        </div>
        {<Content />}
      </div>
    </Zoom>
  );
};

export default PaymentContainer;
