import _ from "lodash";
import constants from "../constants";
import { PAYMENT_METHODS } from "./constants";
export const TRANSACTION_STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  TIMEOUT: "TIMEOUT",
  EXPIRED: "EXPIRED",
  PAID: "PAID",
  IN_PROGRESS: "IN_PROGRESS",
  PARTIALLY_PAID: "PARTIALLY_PAID",
  PENDING: "PENDING",
  USER_CANCELLED: "USER_CANCELLED",
  CANCELLED: "CANCELLED",
  OVERPAID: "OVERPAID",
  PARTIALLY_REFUNDED: "PARTIALLY_REFUNDED",
  REFUNDED: "REFUNDED",
  REJECTED: "REJECTED",
  UNKNOWN: "UNKNOWN",
  BANK_AUTHORIZATION_REQUIRED: "BANK_AUTHORIZATION_REQUIRED",
  MASTERCARD_AUTHORIZATION_REQUIRED: "MPGS_3DS_AUTHORIZATION_REQUIRED",
  OTP_AUTHORIZATION_REQUIRED: "OTP_AUTHORIZATION_REQUIRED",
  TRANSACTION_PENINDG: "Transaction is Pending",
  TRANSACTION_ALREADY_COMPLETED: "transaction already completed",
};
export const PAYMENT_SESSION_STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  REJECTED: "REJECTED",
  CREATED: "CREATED",
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
};

export const PAYMENT_REJECTION_REASONS = {
  UNDER_PAYMENT: "UNDER_PAYMENT",
  OVER_PAYMENT: "OVER_PAYMENT",
};

export const RESPONSE_CODE = {
  TRANSACTION_FAILED: "99",
  TRANSACTION_COMPLETED: "100",
};

export const DISABLED_PAYMENT_METHODS = ["DIRECT_DEBIT", "CASH", "NQR"];

const checkStatus = (status, validStatuses) => {
  if (_.isEmpty(status)) {
    return false;
  }
  return validStatuses.includes(status.toUpperCase());
};

const checkRejectionReason = (reason, validReasons) => {
  if (_.isEmpty(reason)) {
    return false;
  }
  return validReasons.includes(reason.toUpperCase());
};

export function isTransactionUnderpaid(reason) {
  let validReasons = [PAYMENT_REJECTION_REASONS.UNDER_PAYMENT];

  return checkRejectionReason(reason, validReasons);
}

export function isTransactionOverpaid(reason) {
  let validReasons = [PAYMENT_REJECTION_REASONS.OVER_PAYMENT];

  return checkRejectionReason(reason, validReasons);
}

export function isTransactionSuccessful(paymentStatus) {
  let successResponses = [
    TRANSACTION_STATUS.SUCCESS,
    TRANSACTION_STATUS.PAID,
    TRANSACTION_STATUS.OVERPAID,
    TRANSACTION_STATUS.PARTIALLY_PAID,
    TRANSACTION_STATUS.PARTIALLY_REFUNDED,
    TRANSACTION_STATUS.REFUNDED,
  ];

  return checkStatus(paymentStatus, successResponses);
}

export function isPaymentExpired(paymentStatus) {
  let expiredResponses = [TRANSACTION_STATUS.EXPIRED];

  return checkStatus(paymentStatus, expiredResponses);
}

export function isTransactionPending(paymentStatus) {
  let pendingResponse = [
    TRANSACTION_STATUS.PENDING,
    TRANSACTION_STATUS.PARTIALLY_PAID,
  ];

  return checkStatus(paymentStatus, pendingResponse);
}

export function isTransactionCancelled(paymentStatus) {
  let pendingResponse = [
    TRANSACTION_STATUS.CANCELLED,
    TRANSACTION_STATUS.USER_CANCELLED,
  ];

  return checkStatus(paymentStatus, pendingResponse);
}

export function isTransactionExpired(paymentStatus) {
  let responses = [TRANSACTION_STATUS.EXPIRED, TRANSACTION_STATUS.TIMEOUT];

  return checkStatus(paymentStatus, responses);
}

export function isTransactionFailed(paymentStatus) {
  let responses = [
    TRANSACTION_STATUS.FAILED,
    TRANSACTION_STATUS.USER_CANCELLED,
  ];

  return checkStatus(paymentStatus, responses);
}

export function isTransactionRejected(paymentStatus) {
  let responses = [TRANSACTION_STATUS.REJECTED];

  return checkStatus(paymentStatus, responses);
}

export function isPaymentSessionCreated(paymentSessionStatus) {
  let responses = [PAYMENT_SESSION_STATUS.CREATED];

  return checkStatus(paymentSessionStatus, responses);
}

export function isPaymentSessionPending(paymentSessionStatus) {
  let responses = [PAYMENT_SESSION_STATUS.PENDING];

  return checkStatus(paymentSessionStatus, responses);
}

export function isPaymentSessionSuccessful(paymentSessionStatus) {
  let responses = [
    PAYMENT_SESSION_STATUS.SUCCESS,
    PAYMENT_SESSION_STATUS.COMPLETED,
  ];

  return checkStatus(paymentSessionStatus, responses);
}

export function isPaymentSessionRejected(paymentSessionStatus) {
  let responses = [PAYMENT_SESSION_STATUS.REJECTED];

  return checkStatus(paymentSessionStatus, responses);
}

export function isPaymentSessionFailed(paymentSessionStatus) {
  let responses = [PAYMENT_SESSION_STATUS.FAILED];

  return checkStatus(paymentSessionStatus, responses);
}

export function isOtpAuthorizationRequired(paymentStatus) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  return paymentStatus === TRANSACTION_STATUS.OTP_AUTHORIZATION_REQUIRED;
}

export function isBankAuthorizationRequired(paymentStatus) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  return paymentStatus === TRANSACTION_STATUS.BANK_AUTHORIZATION_REQUIRED;
}

export function isMastercardAuthorizationRequired(paymentStatus) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }

  return paymentStatus === TRANSACTION_STATUS.MASTERCARD_AUTHORIZATION_REQUIRED;
}

export function isTransactionCompleted(paymentStatus) {
  if (_.isEmpty(paymentStatus)) {
    return false;
  }
  let responses = [
    TRANSACTION_STATUS.USER_CANCELLED,
    TRANSACTION_STATUS.SUCCESS,
    TRANSACTION_STATUS.PAID,
    TRANSACTION_STATUS.OVERPAID,
  ];
  paymentStatus = paymentStatus.toUpperCase();

  if (responses.indexOf(paymentStatus) > -1) {
    return true;
  }

  return false;
}

export const mapPaymentMethodFromServerToApplication = (paymentStatus) => {
  switch (paymentStatus) {
    case PAYMENT_METHODS.ACCOUNT_TRANSFER:
      return constants.PAY_WITH_ACCOUNT_TRANSFER;
    case PAYMENT_METHODS.CARD:
      return constants.PAY_WITH_CARD;
    case PAYMENT_METHODS.USSD:
      return constants.PAY_WITH_USSD;
    case PAYMENT_METHODS.PHONE_NUMBER:
      return constants.PAY_WITH_PHONE_NO;
    case PAYMENT_METHODS.NQR:
      return constants.PAY_WITH_NQR;
    default:
      return "";
  }
};
