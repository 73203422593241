import React, { useContext, useMemo } from "react";
import constants from "../../constants";
import MonnifyIcons from "../../icon";
import { FAILED_ICON } from "../../icon/icon";
import StoreContext from "../../services/store/store-context";
import TransactionService from "../../services/transaction.service";
import "./index.scss";

const Failed = () => {
  const context = useContext(StoreContext);

  const enabledPaymentMethods = useMemo(
    () => context.getEnabledPaymentMethods(),
    [context?.paymentInfo?.paymentData]
  );

  const isTransactionValid = () =>
    context?.stageMessage !== constants.TRANSACTION_EXPIRED ||
    context?.stageMessage?.responseBody?.message !==
      constants.TRANSACTION_EXPIRED;

  return (
    <div className="status-paymentContainer-wrapper">
      <div className="failed-wrapper">
        <div className="icon pt-3">
          <MonnifyIcons type={FAILED_ICON} />
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h2 className="title text-center">
              {context?.messageTitle || "Transaction Failed!"}
            </h2>
            {context.stageMessage ? (
              <article className="article pt-2">
                {context?.stageMessage?.responseBody?.message ||
                  context?.stageMessage}
              </article>
            ) : (
              <article className="article pt-2">Transaction Failed</article>
            )}
            {isTransactionValid() && (
              <div className="options">
                <ul>
                  {context?.fallBackOptions?.map((item, i) => (
                    <li key={i}>
                      <div onClick={item.onClickHanlder}>{item.text}</div>
                      {item.desc && (
                        <>
                          <span className="text-warning">*{item.desc}</span>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default Failed;
