import React, { useCallback, useContext, useEffect } from "react";
import MonnifyIcons from "../../icon";
import { MESSAGE_TYPE } from "../../services/constants";
import MonnifyPopupService from "../../services/popup.service";
import StoreContext from "../../services/store/store-context";
import TransactionService from "../../services/transaction.service";
import "./index.scss";

const Success = () => {
  const context = useContext(StoreContext);

  const intiateSDKClose = useCallback(() => {
    setTimeout(() => {
      if (MonnifyPopupService.isWebInitialized)
        context.postMessage({
          type: MESSAGE_TYPE.TRANSACTION_SUCCESS,
          data: context?.paymentCompleteInfo,
        });

      if (context.getRedirectUrl()) {
        const redirectUrl = context.getRedirectUrl();
        window.location = redirectUrl.includes("?")
          ? redirectUrl +
            `&paymentReference=${context.paymentCompleteInfo.paymentReference}`
          : redirectUrl +
            `?paymentReference=${context.paymentCompleteInfo.paymentReference}`;
      }
    }, context.getCloseInterval());
  }, []);

  useEffect(() => {
    intiateSDKClose();
  }, [intiateSDKClose]);

  return (
    <div className="status-paymentContainer-wrapper">
      <div className="success-wrapper">
        <div className="icon pt-3">
          <MonnifyIcons type="SUCESS_ICON" />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <h2 className="title text-center">Transaction Successful!</h2>

              <article className="article pt-2">
                Your payment of{" "}
                {TransactionService.formatAsMoney(
                  context?.paymentInfo?.paymentData?.totalAmountPayable ||
                    context?.paymentInfo?.paymentData?.amount ||
                    0
                )}{" "}
                has been successfully processed.
              </article>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
