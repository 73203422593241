import { useCallback, useContext, useEffect, useState } from "react";
import MonnifyIcons from "../../icon";
import { SPINNER_WHITE } from "../../icon/icon";
import StoreContext from "../../services/store/store-context";
import {
  RESPONSE_CODE,
  TRANSACTION_STATUS,
} from "../../services/transaction-status.constant";
import TransactionService from "../../services/transaction.service";
import { Button } from "../../styled-components/Input";
import { STAGES as APP_STAGE } from "../../App";
import { useParams } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Banner } from "../transfer";
import { ERROR_BANNER, PAY_WITH_OTHER_METHODS } from "../../services/constants";
import constants from "../../constants";
import ballsGif from "../../assets/balls.gif";
import Axios from "axios";

let pollingIntervalID;
const controller = new AbortController();

const ConfirmTransaction = (props) => {
  const context = useContext(StoreContext);
  const [checkingPayment, setcheckingPayment] = useState(false);
  const params = useParams();
  const [errorMessage, setErrorMessag] = useState(null);

  const startPolling = useCallback(() => {
    pollingIntervalID = setInterval(async () => {
      try {
        const { transactionStatus } = await queryTransactionStatus();

        if (
          transactionStatus === TRANSACTION_STATUS.EXPIRED ||
          transactionStatus === TRANSACTION_STATUS.FAILED
        ) {
          stopPolling();
          if (context.activeLink === constants.PAY_WITH_PHONE_NO)
            return context.changeTransactionStage(
              APP_STAGE.TRANSACTION_FAILED,
              "Unable to use this payment option at the moment. Please try again shortly. If the issue persists, contact support.Unable to use this payment option at the moment. Please try again shortly. If the issue persists, contact support.",
              "Temporary Error",
              [
                {
                  text: "Try again with Phone No.",
                  onClickHanlder: () => {
                    context.changeTransactionStage(
                      APP_STAGE.TRANSACTION_PROCESSING
                    );
                  },
                },
                {
                  text: PAY_WITH_OTHER_METHODS,
                  onClickHanlder: () => {
                    TransactionService.switchNextPaymentMethod(
                      context,
                      constants.PAY_WITH_PHONE_NO
                    );
                  },
                },
              ]
            );
        } else if (
          transactionStatus === TRANSACTION_STATUS.SUCCESS ||
          transactionStatus === TRANSACTION_STATUS.PAID
        ) {
          stopPolling();
          if (context.activeLink === constants.PAY_WITH_PHONE_NO)
            return onComplete();
        }
      } catch (error) {
        TransactionService.cancelAllRequest();
        stopPolling();
        return context.changeTransactionStage(
          APP_STAGE.TRANSACTION_FAILED,
          "Transaction Failed",
          "Transaction could not be completed",
          [
            {
              text: "Try again with Phone No.",
              onClickHanlder: () => {
                context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_PROCESSING
                );
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_PHONE_NO
                );
              },
            },
          ]
        );
      }
    }, 2000);
  }, [context.activeLink]);

  const stopPolling = () => {
    TransactionService.cancelAllRequest();
    if (pollingIntervalID) {
      return clearInterval(pollingIntervalID);
    }
  };

  const handleOnConfirm = async () => {
    try {
      const response = await queryTransactionStatus();
      setcheckingPayment(true);

      if (response.transactionStatus === TRANSACTION_STATUS.PENDING) {
        // do something here for pending transactioon
      } else if (
        response.transactionStatus === TRANSACTION_STATUS.PAID ||
        response.transactionStatus === TRANSACTION_STATUS.SUCCESS
      ) {
        onComplete(response.transactionStatus, response.responseBody);
      }
      setcheckingPayment(false);

      // onComplete(response.transactionStatus, response.responseBody)
    } catch (error) {
      setcheckingPayment(false);
      let errorData = (error && error.response && error.response.data) || {};
      handleErrorLoadingAccount(errorData);
    }
  };
  const queryTransactionStatus = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response =
          await TransactionService.queryPhoneNumberTransactionStatus(
            context?.paymentInfo?.paymentData?.transactionReference,
            context?.paymentInfo?.paymentData?.apiKey,
            context?.paymentInfo?.configData?.apiUrl
          );
        let responseData = (response && response.data) || {};
        let responseBody = responseData.responseBody;

        let transactionStatus =
          responseBody.status || TRANSACTION_STATUS.EXPIRED;
        resolve({ responseBody, transactionStatus });
      } catch (error) {
        let errorData = (error && error.response && error.response.data) || {};
        reject(errorData);
      }
    });
  };

  const handleErrorLoadingAccount = (data) => {
    setcheckingPayment(true);
    let error =
      (data && data.responseMessage) ||
      "Unable to fetch payment method details. Please try again or use another payment method";

    context.changeTransactionStage(
      APP_STAGE.TRANSACTION_FAILED,
      error,
      "Transaction Failed",
      [
        {
          text: "Try again with Phone",
          onClickHanlder: () => {
            context.changeTransactionStage(APP_STAGE.TRANSACTION_PROCESSING);
          },
        },
        {
          text: PAY_WITH_OTHER_METHODS,
          onClickHanlder: () => {
            TransactionService.switchNextPaymentMethod(
              context,
              constants.PAY_WITH_PHONE_NO
            );
          },
        },
      ]
    );
  };

  const onComplete = (status, data) => {
    TransactionService.queryTransactionStatus(
      context?.paymentInfo?.paymentData?.transactionReference,
      context?.paymentInfo?.paymentData?.apiKey,
      context?.paymentInfo?.configData?.apiUrl
    )
      .then((response) => {
        let responseData = (response && response.data) || {};
        if (
          !responseData ||
          !responseData.requestSuccessful ||
          !responseData.responseBody
        ) {
          return;
        }

        let responseBody = responseData.responseBody;
        let transactionStatus =
          responseBody.paymentStatus || TRANSACTION_STATUS.EXPIRED;

        if (
          transactionStatus === TRANSACTION_STATUS.PAID ||
          transactionStatus === TRANSACTION_STATUS.PARTIALLY_PAID
        ) {
          context.setPaymentCompleteInfo(responseBody);
          return context.changeTransactionStage(
            APP_STAGE.TRANSACTION_SUCCESSFUL
          );
        }
        // this.onFlowComplete(transactionStatus, responseBody);
      })
      .catch((error) => {
        let errorData = (error && error.response && error.response.data) || {};
        // let transactionStatus = errorData && errorData.paymentStatus;

        onComplete(TRANSACTION_STATUS.FAILED, errorData);
      });
  };

  const initializeTransaction = useCallback(async () => {
    try {
      setErrorMessag(null);
      const apiUrl = context?.paymentInfo?.configData?.apiUrl;
      const response = await Axios.post(
        `${apiUrl}/api/v1/sdk/phone-payment/initialize`,
        {
          transactionReference: params.id,
          phoneNumber: props.phoneNumber,
        },
        {
          signal: controller.signal,
        }
      );
      const responseBody = response.data?.responseBody || {};
      if (responseBody.status === TRANSACTION_STATUS.SUCCESS) {
        onComplete(responseBody.status, responseBody);
      } else if (responseBody.status === TRANSACTION_STATUS.PENDING) {
        startPolling();
      } else {
        return context.changeTransactionStage(
          APP_STAGE.TRANSACTION_FAILED,
          null,
          "Transaction Failed",
          [
            {
              text: "Try again with Phone No.",
              onClickHanlder: () => {
                context.changeTransactionStage(
                  APP_STAGE.TRANSACTION_PROCESSING
                );
              },
            },
            {
              text: PAY_WITH_OTHER_METHODS,
              onClickHanlder: () => {
                TransactionService.switchNextPaymentMethod(
                  context,
                  constants.PAY_WITH_PHONE_NO
                );
              },
            },
          ]
        );
      }
    } catch (error) {
      let errorData = (error && error.response && error.response.data) || {};
      let responseCode = errorData.responseCode;
      if (error && error.message === TRANSACTION_STATUS.CANCELLED) {
        return;
      }
      if (
        responseCode === RESPONSE_CODE.TRANSACTION_COMPLETED &&
        context.activeLink === constants.PAY_WITH_PHONE_NO
      ) {
        return context.changeTransactionStage(
          APP_STAGE.TRANSACTION_FAILED,
          "Transaction already completed"
        );
      }
      startPolling();
    }
  }, [
    params.id,
    props.phoneNumber,
    context?.paymentInfo?.configData?.apiUrl,
    onComplete,
    startPolling,
  ]);

  useEffect(() => {
    initializeTransaction();
    return () => {
      stopPolling();
    };
  }, []);

  return (
    <>
      {errorMessage && (
        <>
          <Fade top>
            <Banner type={ERROR_BANNER} text={errorMessage} />
          </Fade>
          <div className="py-1"></div>
        </>
      )}
      <div className="row mb-2">
        <div className="col-md-12 no-horizontal-padding">
          <h3 className="text-center sub-title text-black">
            Kindly check your phone. You'll receive a prompt to complete
            payment.
          </h3>
        </div>
      </div>

      <div className="loading-container-wrap">
        {/* <MonnifyIcons type={ROLLER_ICON} style={{}} /> */}
        <img
          src={ballsGif}
          alt="Monnify"
          style={{
            width: 80,
            height: 80,
            objectFit: "contain",
          }}
        />
        <h3 className="status-text">Awaiting payment completion.</h3>
      </div>

      <div className="mb-3"></div>
      <div className="row">
        <div className="col-md-12 no-horizontal-padding">
          <Button
            onClick={handleOnConfirm}
            style={{ padding: checkingPayment ? "0px 8px" : "10px 8px" }}
          >
            {checkingPayment ? (
              <>
                {"Confirming..."}
                <span>
                  <MonnifyIcons type={SPINNER_WHITE} />
                </span>
              </>
            ) : (
              <>{`I’ve completed this payment`}</>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConfirmTransaction;
